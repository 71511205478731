/* css for sidebar */
/* @import "rsuite/dist/rsuite.css"; */

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Roboto" !important;
  color: #272727;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

a {
  color: #009edb;
}

.nav-link {
  color: #009edb !important;
}

.main-logo {
  width: 370px;
  /* padding: 8px; */
  padding: 0;
}

.main-logo img {
  width: 370px;
}

.header-row {
  color: #eaeaea;
  height: 2px;
}

.userName {
  color: #272727 !important;
  font-family: "Roboto";
}

/* .nav.nav-tabs.card-header-tabs li{ padding-top: 25px;}
.nav.nav-tabs.card-header-tabs li a{ border: none !important;}
.nav.nav-tabs.card-header-tabs li .dropdown-menu{ border: none !important;} */
.right-menu .dropdown-menu {
  border: none !important;
}

.logOut {
  color: #d21818 !important;
  background: #ffffff;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
}

.rs-sidenav-default {
  background-color: #fff !important;
}

#root {
  padding: 10px;
}

@media only screen and (min-width: 768px) {
  .left-border {
    border-left: 0.5px solid;
  }
}

.offcanvas-backdrop.show {
  opacity: 0 !important;
}

.rs-sidenav-default .rs-sidenav-item,
.rs-sidenav-default .rs-dropdown-toggle,
.rs-sidenav-default .rs-sidenav-toggle-button {
  background-color: #fff !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 93.19%;
  /* identical to box height, or 19px */
  /* color: #009edb !important; */
  text-decoration: none;
}

.assesment-dropdown > .rs-dropdown-toggle {
  color: #009edb !important;
}

.rs-sidenav-nav > .rs-sidenav-item,
.rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle {
  padding-left: 0px !important;
}

.rs-sidenav-collapse-in .rs-dropdown-item,
.rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
  padding: 8px 0px 8px 0px !important;
}

.rs-dropdown-menu li a {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
}

/* Sidebar */
.check {
  width: 20px;
}

.dimension{
  font-size: 20px;
}

.indicator{
  font-size: 16px;
}

/* css for question  */
.heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 93.19%;
}

.question-just {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #272727;
}

.my-question ol li {
  /* font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;  */
  font-size: 17px;
  margin: 10px;
  color: #1c1c1c;
  position: relative;
}

.bgColor-Optional{
  background-color:#F4FBFE !important;
}

.bgColor-noQ{
  background-color:#F4FBFE !important;
}

.height-noQ{
  height: 500px !important;
}
.my-label input:checked {
  background-color: #1c1c1c;
  border: 1px #000;
  margin-right: 8px;
  box-shadow: none !important;
}

.form-check-input {
  margin-right: 8px !important;
  border: 1px solid #1c1c1c;
  box-shadow: none !important;
}

/* .form-check-input:focus{
  box-shadow: 0 0 0 0.25rem rgb(34 158 219) !important;
  border-color: #229edb !important;
} */

/* register css */

.auth-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #2d363a;
}

.auth-OTP-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #2d363a;
}

.sign {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  text-transform: uppercase;

  color: #272727;
}

.signIn {
  color: #009edb;
  cursor: pointer;
}

.user-login {
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.user-login h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  text-transform: uppercase;
  color: #272727;
  /* margin-bottom: 0px; */
}

.user-login .login-border {
  color: #009edb;
  width: 47.01px;
  height: 0px;
  left: 701.99px;
  top: 393.5px;
  border: 2px solid #009edb;
}

.custom-label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}
.anchor {
  color: #009edb !important;
  text-decoration: none;
  font-size: 19px;
}

.addPdf {
  color: #009edb !important;
  border: 1px solid #009edb !important;
}

.authButton {
  color: #272727 !important;
  font-weight: 500 !important;
  border: 1px solid #009edb !important;
  width: 200px !important;
  cursor: pointer;
  background: #fff !important;
}

.authButton:hover {
  background-color: #009edb !important;
  color: #fff !important;
}

.downloadButton {
  color: #fff !important;
  font-weight: 500 !important;
  border: 1px solid #009edb !important;
  /* width: 200px !important; */
  cursor: pointer;
  background: #009edb !important;
}

/* analytics convert digit to percent btn */
.analyticsConvertButton {
  color: #272727 !important;
  font-weight: 500 !important;
  border: 1px solid #009edb !important;
  width: 200px !important;
  cursor: pointer;
  background: #fff !important;
}

.analyticsConvertButtonClicked {
  color: #fff !important;
  font-weight: 500 !important;
  border: 1px solid #009edb !important;
  width: 200px !important;
  cursor: pointer;
  background: #272727 !important;
}

.analyticsConvertButton:hover {
  background-color: #009edb !important;
  color: #fff !important;
}

/* Common Button  */
.common-custom-button {
  font-weight: 500 !important;
  border: 1px solid #009edb !important;
  width: 200px !important;
  cursor: pointer;
  background: #009edb !important;
}

.common-custom-button:hover {
  background-color: #000 !important;
  border: 1px solid #000 !important;
}

/* question button */

.question-submit-button {
  border: 1px solid #009edb !important;
  width: 120px !important;
  cursor: pointer;
  color: #272727 !important;
  background: #fff !important;
}

.question-submit-button:hover {
  background-color: #009edb !important;
  /* background-color: #000 !important; */
  border: 1px solid #009edb !important;
  color: #fff !important;
}

.cancelButton {
  width: 120px !important;
  color: #d21818 !important;
}

.backButton {
  width: 120px !important;
  border: 1px solid #CACACA !important;
  /* color: #fff !important; */
}

/* report page */
.profile {
  background-color: #f4fbfe;
}

.profile p .bold {
  font-weight: 500;
}

/* History page */
.table-head {
  background-color: #f4fbfe;
}

.history-text-color {
  color: #4d4d4d !important;
}

.statusComplete {
  color: #fff !important;
  font-weight: 500 !important;
  border: 1px solid #1EC17D !important;
  width: 100px !important;
  cursor: pointer;
  background: #1EC17D !important;
}

.statusPending {
  color: #fff !important;
  font-weight: 500 !important;
  border: 1px solid #E17108 !important;
  width: 100px !important;
  cursor: pointer;
  background: #E17108 !important;
}

/* OTP verify page */
.verfiction-input {
  font-size: 25px !important;
  font-weight: 500 !important;
  border: 1px solid #009EDB !important;
  border-bottom: 4px solid #009EDB !important;
  width: 1.7em !important;
  height: 2em !important;
}

.verfiction-input[value=""] {
  border-color: #C4C4C4 !important;
}

/* Q&A page */
.custom-other-label{
  width: 300px;
  border: 1px solid #DBDBDB ;
}

/* List for question show */
/* .quesList:before{
  margin-top: -.5rem;
  color: #d21818;
} */

/* li::marker {
    margin-top: 5rem !important;
} */

/* Dashborad */
.textColor{
  color: #009EDB;

}

.chart-wrapper{
  width: 400px !important;
}

/* .chart-wrapper-horizontal{
  
} */

.chart-padding-horizontal{
  padding: 0rem 15rem 0rem 15rem !important;
}


/* .pdfClass{
  display: none;
}

body > .pdfClass {
  display: block;
}

@media print {
  .pdfClass{
    display: block;
  }
} */

.pdfFont{
  font-family: 'Roboto Condensed' !important;
}

table.report-container {
    page-break-after:always !important;
}
thead.report-header {
    display:table-header-group !important;
}
tfoot.report-footer {
    display:table-footer-group !important;
} 

table.report-container div.article {
    page-break-inside: avoid !important;
}

/* .header-info{
  padding-right: 12rem !important;
} */

/* body {
  counter-reset: pageNumber;
}
.footer-info span:after{
  counter-increment: pageNumber;
  content: counter(pageNumber);
}

.start-80{
  left: 85;
} */

/* pieLegends */
.pieLegends{
	height: 18px !important;
	width: 18px !important;
	border-radius: 100%;
}

/* Document uploads */
/* .pdfCross{
      margin-left: 94px !important;
      margin-bottom: -20px !important;
} */

.text-color-primary{
  color: #009EDB;
}

/* hide pdf white space */
.react-pdf__Page__annotations{
  display: none !important;
}

.react-pdf__Page__textContent{
  display: none !important;
}

/* input group */
.search-input:focus {
  border-color: transparent !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 2px #EAEAEA !important;
}

.input-group:focus-within {
  outline: 1px solid #009edb;
}

.input-group:focus-within *:focus {
  border-color: transparent !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0px #009edb !important;
}

/* .tANDc { 
  counter-reset: item;
  li{ display: block }
  li:before { content: counters(item, ".") " "; counter-increment: item }
} */

/* ol { counter-reset: item }
li{ display: block }
li:before { content: counters(item, ".") " "; counter-increment: item } */

.bg-Progress{
  background-color: #009EDB !important;
}

/* .bg-Fully-Ready{ */
.bg-Leader{
  background-color: #93C47D !important;
}

/* .bg-Almost-Ready{ */
.bg-Advanced{
  background-color: #B6D7A8 !important;
}

/* .bg-Partially-Ready{ */
.bg-Intermediate{
  background-color: #D9EAD3 !important;
}

/* .bg-Slightly-Ready{ */
.bg-Beginner{
  background-color: #FCE5CD !important;
}

/* .bg-Unready{ */
.bg-Laggard{
  background-color: #E06666 !important;
}

.bg-green{
	background-color: #38761D !important;
	color: #fff;
}

.input-group-left-border-0{
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rs-nav-item-active{
  border: 1px solid #009EDB;
  /* background-color: #DCF5FF; */
}

.bg-btn-analytics{
	background-color: #cbe0e9;
}


/* date picker css */
.react-datepicker-time__caption{
  display: none !important;
}
.react-datepicker__close-icon::after{
  margin-left: 20px !important;
  background-color: red !important;
}
.react-datepicker-time__input{
  margin-left: 0 !important;
}

.react-datepicker__input-time-container{
  padding: .5rem !important;
  width: 100% !important;
  border-top: 1px solid #dee2e6;
  margin: 0 !important;
}

.react-datepicker__month-container{
  border-right: 1px solid #dee2e6;
}

.react-datepicker__header {
  background-color: transparent !important;
  margin-top: .5rem !important;

}

.react-datepicker__navigation-icon{
  margin-top: 2rem !important;
}

.react-datepicker__current-month{
  font-size: 16px !important;
}

.react-datepicker__day-names{
  font-size: 14px !important;
}

.react-datepicker__header {
  border: none !important;
}

.react-datepicker__month{
  font-size: 14px !important;
}

.react-datepicker__day--today{
  background-color: #F2F4F7 !important;
  color: #000 !important;
  border-radius: 50% !important;
}
.react-datepicker__day--in-range{
  background-color: white !important;
  color: #000 !important;
  /* background-color: red !important; */
}

.react-datepicker__day--selected{
  background-color: #009EDB !important;
  color: #fff !important;
  border-radius: 50% !important;
}

.react-datepicker__day--range-end{
  background-color: #009EDB !important;
  color: #fff !important;
  border-radius: 50% !important;
}

.react-datepicker-time__input-container{
  width: 100% !important;
}

.react-datepicker-time__input{
  width: 100% !important;
}

/* background colors */

.bg-supply-chain{
	background-color: #F7F8F8 !important;
}

.bg-ESG{
	background-color: #000 !important;
	color: #fff;
}

.bg-Environment{
	background-color: #38761D !important;
	color: #fff;
}

.bg-Social{
	background-color: #F1C232 !important;
	color: #000;
}

.bg-Governance{
	background-color: #00F !important;
	color: #fff;
}

.bg-green{
	background-color: #93C47D !important;
	color: #fff;
}

.bg-yellow{
	background-color: #F1C232 !important;
	color: #000;
}

.bg-blue{
	background-color: #00F !important;
	color: #fff;
}

.bg-table-head{
	background-color: #F2FBFF !important;
}

/* chart scroll */

#chratScroll{
  overflow-x: scroll !important;
}

#chratScroll > canvas{
  height: 500px !important;
  width: 1700px !important;
}

.table-button{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.condition-Less-1400{
  padding-right: 13rem !important;
}
.condition-1400{
  padding-right: 26rem !important;
}

.condition-1600{
  padding-right: 56rem !important;
}

.fs-14 {
  font-size: 14px !important;
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color) !important;
}

.App {
  display: flex !important;
  flex-direction: column !important;
  height: 100vh !important;
  /* overflow-y: scroll !important; */
}

.PhoneInput {
  input {
    display: block !important;
    width: 100% !important;
    padding: .375rem .75rem !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #212529 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    border-radius: .375rem !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
  }

  input:focus-visible {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
  }
}