
/* / EXTRA LARGE SCREEN / DESKTOP / */
@media (min-width: 2561px) and (max-width: 4000px)  {
    /* insert styles here... */
}

/* / LARGE SCREEN / DESKTOP / */
@media (min-width: 1920px) and (max-width: 2560px)  {
    /* insert styles here... */
}

@media only screen and (min-width: 1300px) {
    /* .header-info{
        padding-right: 25rem !important;
    } */

    /* .textColor{
        color: #e21347;

        } */
}


/* / TABLET LANDSCAPE / DESKTOP / */
@media only screen and (max-width: 1024px) {

}


/* / TABLET LANDSCAPE / DESKTOP / */
@media only screen and (max-width: 992px) {

}


/* / TABLETS PORTRAIT / */
@media only screen and (max-width: 767px) {
    .main-logo { width: 370px; padding: 8px; text-align: center; margin: auto;}
    .main-logo img { width: 370;}
    .right-menu {padding-top: 2rem; text-align: center;}
}

/* / SMARTPHONES LANDSCAPE / */
@media only screen and (max-width: 680px) {
    .login-btn-min-width{ width: 100% !important;}


}

@media only screen and (max-width: 539px) {

    .main-logo { width: 300px; padding: 8px; text-align: center; margin: auto;}
    .main-logo img { width: 300px;}
    .right-menu {padding-top: 2rem; text-align: center;}
}

/* / SMARTPHONES LANDSCAPE / */
@media only screen and (max-width: 480px) {
    .main-logo { width: 300px; padding: 8px; text-align: center; margin: auto;}
    .main-logo img { width: 300px;}
    .right-menu {padding-top: 2rem; text-align: center;}
    
   
}


/* / SMARTPHONES PORTRAIT / */
@media only screen and (max-width: 360px) {

    .main-logo { width: 280px; padding: 8px; text-align: center; margin: auto;}
    .main-logo img { width: 280px;}
    .right-menu {padding-top: 2rem; text-align: center;}
    
}
















